export const downloadFile = ({ filename, url }) => {
  const element = document.createElement("a");
  element.setAttribute("href", url);
  element.setAttribute("download", filename);
  document.body.appendChild(element);
  element.click();
  element.remove();
};

/**
 * fetch方法模板
 * @param {string} url
 * @param {object} options
 */
export function fetchJSON(url, options = {}) {
  return fetch(url, options)
    .then((res) => {
      if (!(res.status === 200)) throw res.json();
      return res.json();
    })
    .then((json) => json)
    .catch((error) => {
      throw error;
    });
}
