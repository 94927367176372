import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import yaml from "js-yaml";
// Images
import CLOSE from "../assets/image/btn_close@2x.png";
import CLOSE_HOVER from "../assets/image/btn_close_hover.png";
import CONTENT from "../assets/image/img_download_pic.png";
import WINDOWS from "../assets/image/ico_windows@2x.png";
import MAC from "../assets/image/ico_apple@2x.png";
import iOS from "../assets/image/ico_ios@2x.png";
import iOS_BANNER from "../assets/image/ico_app_store@2x.png";
import ANDROID from "../assets/image/ico_android@2x.png";
import ANDROID_BANNER from "../assets/image/ico_google_play_store@2x.png";
// Data
import {
  containerURL,
  windowos,
  //   window32,
  mac,
  ios,
  iosCN,
  android,
  androidCN,
} from "../data/Download.json";

import "../assets/stylesheet/DownloadModal.scss";
import { downloadFile } from "../helpers";
import { version } from "../../package.json";

const DownloadIcon = React.lazy(() => import("./DownloadIcon"));

const DownloadModal = () => {
  const navigate = useNavigate();
  const isDesktop = useMediaQuery({ query: "(min-width: 992px)" });
  const [isHover, toggleHover] = useState(false);
  //   const [isWindowBitOn, setWindowBitOn] = useState(false);
  const [isCN, setCN] = useState(false);
  //   const [bitDownloadLoading, setLoading] = useState(false);
  //   const [bitOption, setOption] = useState("");

  const handleCNClick = () => {
    setCN(!isCN);
  };
  //   const handleBitLoading = (bit, callback) => {
  //     if (bitDownloadLoading) return;
  //     setLoading(true);
  //     setOption(bit);
  //     callback();
  //     setTimeout(() => {
  //       setLoading(false);
  //       setOption("");
  //     }, 3000);
  //   };

  const downloadTitle = "歡迎下載CoLine，支援多種平台與裝置";
  //   const selectBit = "請選擇位元版本";
  //   const bit32 = bitDownloadLoading && bitOption === "32" ? "下載中" : "32位元";
  //   const bit64 = bitDownloadLoading && bitOption === "64" ? "下載中" : "64位元";

  const closeClick = () => {
    navigate("/");
  };

  const parseYml = (type = "dmg") => {
    // console.log("version", version);
    const filename =
      type === "dmg"
        ? `CoLine-${version}.${type}`
        : `CoLine Setup ${version}.${type}`;
    const folder = type === "dmg" ? "darwin" : "win";

    try {
      downloadFile({
        filename: filename,
        url: `${containerURL}/${folder}/${filename}`.replace(/ /g, "%20"),
      });
    } catch (error) {
      console.error("parse yaml error", error.message);
    }
  };

  useEffect(() => {
    setCN(false);
  }, []);

  return (
    <>
      <div className="fade modal-backdrop show"></div>
      <div
        className="modal fade show d-block"
        role="dialog"
        tabIndex="-1"
        aria-modal="true"
      >
        <div
          className={`modal-dialog modal-${isDesktop ? "lg" : "md"}`}
          role="document"
        >
          <div className="modal-content">
            <div className="p-0 m-0 modal-body">
              <div
                className={`rounded download-modal-content${
                  isDesktop ? "" : "-small"
                }`}
              >
                <div
                  className="close"
                  onClick={closeClick}
                  onMouseOver={() => toggleHover(true)}
                  onMouseLeave={() => toggleHover(false)}
                >
                  <img
                    alt="download-modal-close"
                    src={isHover ? CLOSE_HOVER : CLOSE}
                    className="pointer"
                    draggable={false}
                  />
                </div>
                <div className="content">
                  <div
                    className={`download-modal-body${
                      isDesktop ? "" : "-small"
                    }`}
                  >
                    <h5 className={`text-center${isDesktop ? "" : " py-5"}`}>
                      {downloadTitle}
                    </h5>
                    {isDesktop && (
                      <img
                        alt="download-modal-content"
                        src={CONTENT}
                        draggable={false}
                      />
                    )}
                    <div className="row device">
                      <div className={`col`}>
                        <DownloadIcon
                          IMAGE={WINDOWS}
                          MOBILE={null}
                          id="download-window-btn"
                          name="Windows"
                          href={null}
                          clickEvent={() => parseYml("exe")}
                        />
                      </div>
                      <div className={`col`}>
                        <DownloadIcon
                          IMAGE={MAC}
                          MOBILE={null}
                          id="download-mac-btn"
                          name="Mac"
                          href={null}
                          clickEvent={() => parseYml("dmg")}
                        />
                      </div>
                      {isDesktop && (
                        <div className="col">
                          <DownloadIcon
                            IMAGE={iOS}
                            MOBILE={iOS_BANNER}
                            id="download-ios-btn"
                            name={isCN ? "iOS(簡體)" : "iOS"}
                            href={isCN ? iosCN : ios}
                          />
                        </div>
                      )}
                      {isDesktop && (
                        <div className="col">
                          <DownloadIcon
                            IMAGE={ANDROID}
                            MOBILE={isCN ? null : ANDROID_BANNER}
                            id="download-android-btn"
                            name={isCN ? "Android(簡體)" : "Android"}
                            href={isCN ? androidCN : android}
                          />
                        </div>
                      )}
                    </div>
                    {!isDesktop && (
                      <div className="row device">
                        <div className="col">
                          <DownloadIcon
                            IMAGE={iOS}
                            MOBILE={iOS_BANNER}
                            id="download-ios-btn"
                            name={isCN ? "iOS(簡體)" : "iOS"}
                            href={isCN ? iosCN : ios}
                          />
                        </div>
                        <div className="col">
                          <DownloadIcon
                            IMAGE={ANDROID}
                            MOBILE={isCN ? null : ANDROID_BANNER}
                            id="download-android-btn"
                            name={isCN ? "Android(簡體)" : "Android"}
                            href={isCN ? androidCN : android}
                          />
                        </div>
                      </div>
                    )}
                    <div className="text-center">
                      <b
                        className="pointer"
                        style={{ color: isCN ? "#0e9073" : "#808080" }}
                        onClick={handleCNClick}
                      >
                        {isCN ? "下載繁體中文版" : "下載簡體中文版"}
                      </b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {isWindowBitOn && (
        <>
          <div className="fade modal-backdrop show backdrop-result"></div>
          <div
            className="modal fade show d-block"
            role="dialog"
            tabIndex="-1"
            aria-modal="true"
          >
            <div
              className="modal-dialog modal-sm bit-window-result"
              role="document"
            >
              <div className="modal-content">
                <div className="p-0 m-0 modal-body">
                  <div
                    className="d-flex align-items-center justify-content-center bit-close"
                    onClick={() => setWindowBitOn(false)}
                  >
                    <img
                      alt="contact-modal-close"
                      src={CLOSE}
                      className="pointer"
                      width="15"
                      height="15"
                      draggable={false}
                    />
                  </div>
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ width: "300px", height: "50px" }}
                  >
                    <h5>{selectBit}</h5>
                  </div>
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ width: "300px", height: "150px" }}
                  >
                    <button
                      className="btn bit-btn mx-2"
                      onClick={() => {
                        handleBitLoading(
                          "32",
                          () => (window.location = window32)
                        );
                      }}
                    >
                      {bit32}
                    </button>
                    <button
                      className="btn bit-btn mx-2"
                      onClick={() => {
                        handleBitLoading(
                          "64",
                          () => (window.location = windowos)
                        );
                      }}
                    >
                      {bit64}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )} */}
    </>
  );
};

DownloadModal.propTypes = {
  size: PropTypes.string,
  show: PropTypes.bool,
  isHover: PropTypes.bool,
  toggleShow: PropTypes.func,
  toggleHover: PropTypes.func,
  children: PropTypes.any,
};

export default DownloadModal;
