import React, { lazy, Suspense, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
// Stylesheets
import "./assets/stylesheet/index.scss";
import DownloadModal from "./components/DownloadModal";

// Pages
const TopDisplay = lazy(() => import("./pages/Top"));
const OriginDisplay = lazy(() => import("./pages/Origin"));
const FeatureDisplay = lazy(() => import("./pages/Feature"));
const AdvantageDisplay = lazy(() => import("./pages/Advantage"));
const DetailDisplay = lazy(() => import("./pages/Detail"));
const QADisplay = lazy(() => import("./pages/FAQ"));
const PracticalsDisplay = lazy(() => import("./pages/Practicals"));
// Components
const ContactUsModal = lazy(() => import("./components/ContactUsModal"));
const NavigationContainer = lazy(() =>
  import("./components/NavigationContainer")
);
const Section = lazy(() => import("./components/Section"));
const FloatButton = lazy(() => import("./components/FloatButton"));
const Content = lazy(() => import("./components/PracticalContent"));
const CONTENTS = [
  { href: "", name: "top", className: "top", title: "", component: TopDisplay },
  {
    href: "#origin",
    name: "origin",
    className: "origin",
    title: "CoLine緣起",
    component: OriginDisplay,
  },
  {
    href: "#feature",
    name: "feature",
    className: "feature",
    title: "基本功能",
    component: FeatureDisplay,
  },
  {
    href: "#advantage",
    name: "advantage",
    className: "advantage",
    title: "系統優勢",
    component: AdvantageDisplay,
  },
  {
    href: "#detail",
    name: "detail",
    className: "detail",
    title: "功能應用",
    component: DetailDisplay,
  },
  {
    href: "#faq",
    name: "faq",
    className: "faq",
    title: "Q&A",
    component: QADisplay,
  },
];

const App = (props) => {
  const navigate = useNavigate();
  const scrollAction = () => window.scrollTo({ top: 0, behavior: "smooth" });
  const toggleContactShow = () => navigate("/contact");

  return (
    <Suspense fallback={<div></div>}>
      <NavigationContainer
        {...props}
        CONTENTS={CONTENTS}
        toggleContactShow={toggleContactShow}
      />
      <Routes>
        <Route
          path="/"
          element={<Main {...props} toggleContactShow={toggleContactShow} />}
        >
          <Route path="download" element={<DownloadModal />} />
          <Route path="contact" element={<ContactUsModal size="lg" />} />
        </Route>
        <Route path="/practicals" element={<PracticalsDisplay />} />
        <Route path="/practicals/:id" element={<Content />} />
        <Route
          path="*"
          element={<Main {...props} toggleContactShow={toggleContactShow} />}
        ></Route>
      </Routes>
      <FloatButton id="scroll-top-btn" type="up" clickEvent={scrollAction} />
      <FloatButton
        id="contact-form-btn"
        type="mail"
        clickEvent={toggleContactShow}
      />
    </Suspense>
  );
};

const Main = (props) => {
  return (
    <>
      {[...CONTENTS].map((item) => {
        const Component = item.component || null;
        return (
          <>
            {item.href && (
              <Section
                key={item.name}
                name={item.name}
                className={item.className}
              >
                <Component
                  {...props}
                  toggleContactShow={props.toggleContactShow}
                />
              </Section>
            )}
            {!item.href && (
              <section key={item.name} className={item.className}>
                <Component
                  {...props}
                  toggleContactShow={props.toggleContactShow}
                />
              </section>
            )}
          </>
        );
      })}
    </>
  );
};

export default App;
